.adm-resumen{
    display: grid;
    grid-gap: 0.8rem;
    margin-top: 0.5rem;
}

.adm-resumen > div {
    line-height: 1.2;
}

.adm-resumen > div > div:first-child {
    margin-bottom: 0.4rem;
}
/**********************LIBERACION******************************/

.adm-resumen.liberacion {
    grid: auto-flow / 1.2fr 1fr 1fr 1fr 1fr;
}

.liberacion-documentos table label{
    margin-bottom: 0;
}

/**********************FIN LIBERACION******************************/

/**********************VACACIONES******************************/

.adm-resumen.vacaciones {
    grid-template-columns: 1fr 0.8fr 1.2fr 1fr 1.2fr;
}

/*********************FIN VACACIONES******************************/

/*********************LIQUIDACIONES******************************/
.adm-liquidacion-detalle{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.custom-tab-header {
    min-width: 70px; /* Adjust the width as per your design/layout */
    max-width: 80px; /* Adjust the width as per your design/layout */
    text-align: center; /* Center align tab text */
}
.custom-tab-small-header {
    min-width: 100px; /* Adjust the width as per your design/layout */
    max-width: 90px; /* Adjust the width as per your design/layout */
    text-align: center; /* Center align tab text */
}
.custom-tab-large-header {
    min-width: 120px; /* Adjust the width as per your design/layout */
    max-width: 250px; /* Adjust the width as per your design/layout */
    text-align: center; /* Center align tab text */
}
.tabview-container {
    height: 500px; /* Altura fija del TabView */
    overflow-y: auto; /* Añade scroll vertical si es necesario */
    border: 1px solid #ccc; /* Borde para visualización */
}

.adm-resumen-viajes{
    display: flex;
    padding: 20px;
}

.adm-resumen-viajes .complementos {
    flex-grow: 1;
}

.adm-resumen-viajes .complementos > div {
    display: flex;
}

.adm-resumen-viajes .totales {
    flex-grow: 4;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.adm-resumen-viajes .totales tbody > tr td{
    padding-top: 5px;
}

.adm-resumen-viajes .totales tbody > tr td:first-child {
    font-weight: bold;
    padding-right: 15px;
}

.adm-liquidacion-detalle .detalle-viaje{
    display: flex;
    flex-direction: column;
}

.adm-liquidacion-detalle .detalle{
    flex-direction: column;
}


/*********************FIN LIQUIDACIONES******************************/