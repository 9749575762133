.g-diaria {
    width: 100%;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
}

.g-diaria .calendario {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 115px repeat(5, 1fr);
    position: relative;
    height: 100%;
    overflow: auto;
    font-size: 0.9em;
}

.g-diaria .header-diarios {
    border-bottom: 1px solid #dedddd;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f3f5fb;
    text-align: center;
}



.g-diaria .unidad {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 20px;
    border: 1px solid #dedddd;
    border-top: none;
    font-size: 0.9em;
    padding: 5px;
}

.g-diaria .unidad-nombre{
    font-size: 0.95em;
}

.g-diaria .unidad-icon{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: gray;
}

.g-diaria .header-unidad {
    border: none;
    border-bottom: 1px solid #dedddd;
    display: flex;
    padding: 5px;
    font-size: 0.85em;
    position: sticky;
    top: 0;
    z-index: 2;
    flex-direction: column;
    background-color: #f3f5fb;
}

.g-diaria .unidades-negocio{
    flex-grow: 1;
    border: 1px solid #dedddd;
    margin-top: 9px;
    margin-bottom: 3px;
}

.captura .alerta-estatus-diario{
    cursor: default;
}

.alerta-estatus-diario{
    font-size: 1.5em;
    color: lightgray !important;
}

.alerta-estatus-diario.con-alerta{
    color: red !important;
}

.alerta-estatus-diario.con-alerta path{
    stroke: red;
}

.operador-estatus-diario svg{
    fill: lightgray;
}

.g-diaria .unidades-negocio{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5px;
}

.g-diaria .unidades-negocio .selected{
    font-weight: bold;
}

.operador-estatus-diario.sin-operador svg{
    fill: red;
}

.operador-estatus-diario.postura svg{
    fill: rgb(255, 192, 0);
}


.g-diaria .header-unidad .unidades-count > span {
    font-size: 1.15em;
    font-weight: bold;
}

.g-diaria .economico {
    font-size: 1.2em;
    font-weight: bold;
}

.g-diaria .cell {
    display: grid;
    border-right: 1px solid #dedddd;
    border-bottom: 1px solid #dedddd;
    background-color: white;
    padding: 3px 8px 3px 3px;
    grid-template-columns: 3px auto;
    grid-template-rows: repeat(3, 1fr);
    font-size: 0.85em;
    position: relative;
}

.g-diaria .cell .info {
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.g-diaria .info .titulo {
    overflow: hidden;
    text-overflow: ellipsis;
}

.g-diaria .info.ruta {
    font-size: 0.8em;
    color: dimgray;
}

.g-diaria .con-comentarios {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    position: relative;
}

.g-diaria .img-commentarios {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.g-diaria .sin-operador-card {
    position: absolute;
    top: 28px;
    right: 10px;
}

.g-diaria .img-viajes {
    width: 28px;
    height: 17px;
    position: absolute;
    top: 0;
    right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eaeaea;
    border-radius: 5px;
}

.g-diaria .cell .facturacion {
    color: #4b6fdb;
    font-weight: bold;
    position: relative;
    text-align: left;
}

.g-diaria .cell .facturacion > img {
    position: absolute;
    top: 0;
    right: 0;
}

.g-diaria .facturado {
    border-bottom: 1px solid #dedddd;
    width: 100%;
    font-weight: bold;
    font-size: 1.2em;
    height: 30px;
}

.estatus-count-dias {
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.estatus-count {
    display: flex;
    flex-direction: column;
    width: 36%;
    max-width: 110px;
    margin-right: 5px;
    cursor: pointer;
    border:  1px solid #dedddd;
    position: relative;

}

.estatus-count .exportar-disponibles{
    position: absolute;
    top: 4px;
    right: 4px;
}

.estatus-count .motivos{
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
}

.estatus-count .motivos:hover, .estatus-count .motivos.seleccionado {
    border-width: 1px;
    border-style: dashed;
    border-color:red;
}

.header-diarios .filtro-sin-operador, .header-diarios .filtro-postura{
    font-size: 18px;
}

.header-diarios .filtro-postura{
    font-size: 18px;
}

.header-diarios .filtro-sin-operador:hover,.header-diarios .filtro-sin-operador.seleccionado{
    border-width: 1px;
    border-style: dashed;
    border-color:red;
}

.header-diarios .filtro-postura:hover,.header-diarios .filtro-postura.seleccionado{
    border-width: 1px;
    border-style: dashed;
    border-color:rgb(255, 192, 0);
}

.subEstatus-count {
    display: grid;
    grid-template-columns: 3px auto;
    border: 1px solid #dedddd;
    width: 30%;
    /* max-width: 10px; */
    /* margin-right: 5px; */
    cursor: pointer;
    font-size: 9px;
}

.g-diaria .cantidad {
    font-weight: bold;
}

.g-diaria .porcentaje {
    font-size: 0.8em;
    color: gray;
}

.g-diaria .day-name {
    font-size: 0.85em;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 30px;
    padding-bottom: 10px;
    position: relative;
}

.g-diaria .operando {
    background-color: #3aea96;
}

.g-diaria .enMantenimiento {
    background-color: #ffc000;
}

.g-diaria .conProblemas {
    background-color: #ff5353;
}

.font-blue {
    color: #4b6fdb;
}
.bold {
    font-weight: bold;
}

.form-g-diaria {
    display: flex;
    flex-direction: column;
    grid-gap: 10px 0;
    text-align: left;
    justify-content: flex-start;
}

.g-diaria .captura {
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    position: relative;
    width: 100%;
}

.g-diaria .captura .municipio {
    display: flex;
    position: relative;
    color: gray;
    width: 100%;
    justify-content: center;
    min-height: 15px;
}

.form-g-diaria .unidad {
    color: #4b6fdb;
    font-weight: bold;
    font-size: 1.1em;
}

.form-g-diaria .col-2 {
    grid-column-end: span 2;
}
.form-g-diaria .divider {
    grid-column-end: span 2;
    border-bottom: 1px solid #dedddd;
}

.form-g-diaria button.estatus,
.captura button.estatus {
    border: 1px solid #dedddd;
    background: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 40px;
}

.captura button.estatus.compact-mode {
    max-width: 40px;
    height: 20px;
    flex-grow: 1;
}

.g-diaria button.estatus.disabled {
    cursor: default;
}

.form-g-diaria .viajes {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 25px;
}

.form-g-diaria .right {
    text-align: right;
}

.form-g-diaria .detalle {
    font-size: 0.8em;
}

.form-g-diaria button.estatus .bar,
.captura button.estatus .bar {
    height: 50%;
    margin-right: 10px;
    flex: 0 0 3px;
}

.form-g-diaria button.estatus .label {
    flex: 1 1 1px;
}

.form-g-diaria .p-datepicker-calendar td {
    padding: 0;
}

.form-g-diaria .p-datepicker table td > span {
    width: 2rem;
    height: 2rem;
}

.dialog-g-diaria.p-dialog .p-dialog-header {
    padding-top: 8px;
    padding-bottom: 8px;
}

.dialog-g-diaria.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    color: gray;
    font-size: 0.9em;
}

.mask-g-diaria.p-dialog-mask {
    align-items: flex-start;
    padding-top: 150px;
}

.tooltip-viajes {
    font-size: 0.7em;
    display: grid;
    grid-template-columns: 1.5fr 8fr 6fr 2fr;
}

.tooltip-viajes-comentarios {
    font-size: 0.75em;
    width: 200px;
}

.tooltip-viajes > div {
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.tooltip-viajes > div.right {
    text-align: right;
}

.g-diaria-leyendas {
    /* padding-top: 28px; */
    grid-gap: 5px;
    font-size: 0.8em;
    position: fixed;
    top: 15px;
    left: calc(50% - 250px);
    display: flex;
    justify-content: center;
}

@media (width <= 1200px) {
    .g-diaria-leyendas {
        display: none;
    }
}

.g-diaria-leyendas > span {
    padding: 3px;
    color: white;
    height: 23px;
    border-radius: 4px;
    width: 120px;
    text-align: center;
}


.g-diaria-leyendas > span.operando {
    color: black;
}

.p-dialog.p-component.p-dialog-content-Overflow.p-dialog-enter-done > .p-dialog-content {
    overflow-y: visible;
}
