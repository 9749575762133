

@media (max-width: 576px) {

    .grid-principal {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch; 
    }

    .form-group label {
        font-size: 14px; 
    }

    .btn {
        width: 100%; /* Los botones ocuparán todo el ancho disponible */
        margin-bottom: 0.5rem; /* Espaciado entre botones */
    }

    .d-flex {
        justify-content: center;
        gap: 0.5rem; /* Espacio entre botones */
    }
}
@media (min-width: 577px) {
    .header-button {
        width: 60vw;
    }
}
.header-icons {
    gap: 10px;
    display: flex;
    justify-content: start; 
}

.header-button {
    padding: 10px 15px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    z-index: 10; 
}


.header-button:hover {
    background-color: #f1f1f1;
    transform: scale(1.05);
}

@media (max-width: 576px) {
    .header-button {
        font-size: 12px;  
        padding: 8px 10px;
    }
}

