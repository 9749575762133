
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

article .p-component, article .p-inputtext, body.combustion, .p-dropdown-panel.p-component, .p-sidebar .p-component,
.p-sidebar .p-component, .p-sidebar .p-inputtext
{
    font-size: 0.8rem;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.bold {
    font-weight: bold;
}



#root {
    height: 100%;
}

.main {
    height: 100%;
}

.main > article {
    height: 100%;
}

.p-component.main-tab-view {
    height: 100%;
}

.ag-theme-balham .grid-principal {
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.link{
    color: #0b7ad1;
    cursor: pointer;
}
/******************************************FORMAS *******************************************************/
.form-container.full-height {
    overflow-y: auto;
    height: calc(100vh - 110px);
}

.is-tablet .form-container.full-height {
    height: calc(100vh - 200px);
}

.form-container.full-height .form-row {
    margin-right: 0;
}

.form-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    padding-right: 1rem;
}

.form-footer > button:not(:last-child) {
    margin-right: 20px;
}

.col-auto > .p-button {
    padding-right: 5px;
    padding-left: 5px;
    white-space: nowrap;
}

.col-auto > .p-button .p-button-icon-left {
    margin-right: 0.1rem;
}

.checkbox-container{
    padding-top: 5px ;
}

.radiobutonlist-container{
    padding-top: 22px;
}

/******************************************TERMINA FORMAS*******************************************************/

/***********************ACCORDION******************************/
.p-accordion-tab-active .accordion-description {
    display: none;
}

.p-accordion-header-text {
    color: black;
}

.accordion-description {
    margin-left: 20px;
    color: darkgray;
}

/***********************end ACCORDION******************************/

/***********************SECCIONES******************************/
.section-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 0.8rem;
}

.section-container > div {
    background: white;
    padding: 0.7rem;
}

.section-container h6, .section-container h5 {
    margin-bottom: 1rem;
}

.section-container > .flex-col-12 {
    width: 100%;
}

.section-container > .flex-col-6 {
    width: calc(50% - 0.5rem);
}

.section-container > .flex-col-7 {
    width: calc(58.33% - 0.5rem);
}

.section-container > .flex-col-5 {
    width: calc(41.66% - 0.5rem);
}


/***********************END SECCIONES******************************/

/***********************LOADING MODAL******************************/
.loading-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: gray;
    opacity: 0.4;
    z-index: 10;
}

.loading-container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
    z-index: 12;
}


/***********************END LOADING MODAL******************************/


.border-top {
    border-top: 1px solid lightgrey;
}

.border-bottom {
    border-bottom: 1px solid lightgrey;
}

.border-left {
    border-left: 1px solid lightgrey;
}

.border-right {
    border-right: 1px solid lightgrey;
}


.right .ag-header-cell-label {
    justify-content: flex-end;
}

.left .ag-header-cell-label {
    justify-content: flex-start;
}

.center .ag-header-group-cell-label{
    text-align: center;
    justify-content: center;
}

.flex.ag-cell{
    display:flex;
}

.flex.align-center.ag-cell{
    align-items: center;
}



.form-control:disabled, .form-control[readonly] {
    background-color: #F2F4F5 !important;
    opacity: 1 !important;

}

.prime-form-control .p-autocomplete-input.p-disabled {
    background-color: #F2F4F5 !important;
    opacity: 1 !important;
}

.p-inputnumber.p-component.form-control.disabled {
    opacity: 1 !important;
    background-color: #F2F4F5 !important;
}

.p-disabled, .p-component:disabled {
    opacity: 1 !important;
    background-color: #F2F4F5 !important;
}

.p-inputnumber:disabled .p-component.form-control {
    opacity: 1 !important;
    background-color: #F2F4F5 !important;
}

.circle-status {
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem
}

.circle-status.success {
    border: 2px solid #3bc379;
    background-color: #a6e3c2;
}

.circle-status.primary {
    border: 2px solid #5e7fde;
    background-color: #bdcbf2;
}

.circle-status.warning {
    border: 2px solid #c71c1c;
    background-color: #e9a6a6;
}

.circle-status.info{
    border: 2px solid #ffff1a ;
    background-color: #ffffc4;    
}

.main-screen {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.divider{
    width: 100%;
    background-color: #dedddd;
    height: 1px;
}

.form-filter{
    margin-top: 5px;
    margin-bottom: 10px;
}

.form-field {
    margin-bottom: 10px;
}

.wrench {
    font-size: 10px;
    fill: #0d89ec;
}


/***************************** NOTIFICACIONES **********************************/

.user-notification{
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    padding: 5px 5px 0 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,.2);
    margin-bottom: 5px;
    font-size: 0.8rem;
}

.user-notification .notification-header{
    font-weight: 500;
    color:black;
}

.user-notification .notificacion-mensaje{
    font-size: 0.75rem;
}

.user-notification .ver-mas {
    color: #0081ff;
    font-weight: 500;
    font-size: 10px;
    margin-top: 3px;
    line-height: normal;
    display: flex;
    justify-content: space-between;
}

.user-notification .ver-mas label{
    cursor: pointer;
}

.download{
    cursor: pointer;
}

.text-label{
    color: #a4a3aa;
}

.text-value{
    font-weight: bold;
}


.td-no-break {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
}