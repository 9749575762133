.button-icon{
    border: 0;
    font-size: 1.6rem;
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

body > iframe{
    display: none;
}

.button-icon.small{
    border: 0;
    font-size: 1.3rem;
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}


button.button-icon{
    color: black;
    padding: 0;
}

button.button-icon.icon{
    cursor: default;
}


.button-icon.BsSearch  {
    font-size: 1.3rem;
    color: black;
}

.button-icon.RiErrorWarningLine{
    color: red;
}

.button-icon.disabled{
    cursor: not-allowed;
}

.table-detail .header-icons .button-icon{
    height: auto;
    width: 100%;
    font-size: 1.5em;
}


/******************************************ACORDION*******************************************************/
.p-accordion-header-link .button-icon.RiErrorWarningLine{
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 1.4rem;
 }
/******************************************ACORDION*******************************************************/

.button-icon.FcClearFilters > * > *:not(rect),
.button-icon.FcFilledFilter > * > *:not(rect){
    fill: black !important;
}

.button-icon.MdAddBox  {
    font-size: 2rem;
}

.button-icon.FcCancel * {
    fill: black ;
}

button.button-icon.action{
    background-color: #2760D3;
    color: white;
}

button.button-icon.action.FaCheckCircle{
    background-color: #2760D3;
    color: white;
    border-radius: 5px;
    font-size: 1.4rem;
    width: 32px;
    height: 32px;
}

.header-icons button.button-icon.grid-action{
    color: #2760D3;
    outline: none;
}

.form-filtro > .iconos {
    position: absolute;
    top: -50px;
    right: 0;
    display: flex;
    margin-right: 10px;
}

.form-filtro > .iconos button.button-icon:hover,
.main-filter .iconos button.button-icon:hover
{
    border: 1px solid gray;
}



.form-filtro > .field-iconos {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    margin-right: 10px;
}

.field-iconos button.button-icon:hover{
    border: 1px solid lightgray;
    /*background-color: white;*/
}

.field-iconos button.button-icon.FaCheckCircle:hover{
    background-color: white;
    color: #2760D3;
}


button.secondary-icon.button-icon {
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 5px;
}

button.secondary-icon.button-icon:hover {
    background-color: #F3F5FB;
    border-radius: 5px;
}

button.secondary-icon.secondary-action.button-icon:not(.disabled){
    background-color: #2760D3;
    color: white;
}

.check-blue{
    color: blue;
    font-size: 20px;
}

.ag-header-cell .header-icons{
    display: flex;
}

.ag-pinned-right-header .header-icons{
    position: absolute;
    top: 0;
    right: 0;
}

.ag-grid.row-icons{
    position: absolute;
    top: -5px;
    right: 13px;
    display: flex;
    width: 35px;
    height: 35px;
}

.ag-grid.row-icons button:not(:last-child) {
    margin-right: 8px;
}

.ag-grid.row-icons .button-icon,
.table-button .button-icon{
    font-size: 1.2rem;
 }

.form-filtro > .iconos button:not(:last-child),
.main-filter .iconos button:not(:last-child)
{
    margin-right: 5px;
}

.form-filtro > .iconos button,
.main-filter .iconos button
{
    border: 1px solid lightgray;
    border-radius: 5px;
}

.table-sm .table-button .button-icon{
    height: 20px;
}

.button-icon.IoClose * {
    outline: none;
}