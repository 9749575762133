.ag-cell .ocash-ruta{
    white-space:normal;
    word-wrap: break-word;
    line-height: normal;
    font-size: 0.85em;
}

.ocash-fecha{
    display: flex;
    flex-direction: column;
    line-height: normal;
}

.ocash-fecha .usuario{
    font-weight: bold;
}

.ag-header-cell-text{
    white-space:normal;
    text-align: center;
}

.grid-cell.ocash-evento-cancelado{
    background-color: #f88d8d;
    font-weight: bold;
}

.ocash-historial{
    font-size: 0.8rem;
}

.ocash-cell-label{
    color: #6c757d;
}

.ocash-table{
    font-size: 0.9em;
}



button.button-icon.ocash-cancelado{
    color: red;
}
