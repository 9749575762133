.tab {
    width: 100%;
}

.tab-tabs {
    display: flex;
    grid-gap: 5px;
    margin-bottom: 10px;
    justify-content: center; 
    align-items: center; 
}

.tab-tabs .tab-tab {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    border: 1px solid #dedddd;
    padding: 10px; 
    font-size: 1.3em;
    color: white;
   background-color: #007bff;
    grid-gap: 3px;
    min-width: 140px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center; 
}
.tab-tabs.movimientos .tab-tab {
    padding: 10px; 
}

.tab-tabs .tab-tab:hover {
    background-color: #e9ecef; 
    color: #0056b3; 
    border: 1px solid #007bff; 
}
.tab-tabs .tab-tab.activa {
    background-color: #007bff; 
    color: white; 
    border: 1px solid #007bff; 
    box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3); 
    cursor: default;
}

