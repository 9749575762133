.com-ruta-aceptada [col-id="estatus.nombre"]{
    color: blue;
    font-weight: bold;
}

.com-ruta-rechazada [col-id="estatus.nombre"]{
    color: gray;
    font-weight: bold;
}

/******************************************PARADAS**************************************/
.com-paradas{
    display: flex;
}

.com-parada{
    padding: 0 2rem 2rem 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
}

.com-parada-header{
    display: flex;
    height: 30px;
    align-items: center;
    margin-bottom: 1rem;
}

.com-parada-header > span{
    font-weight: bold;
    text-transform: uppercase;
}

.submitted .com-parada-header.error .button-icon {
    color: red;
}

.com-parada-direccion
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.com-separador{
    border-bottom: 1px solid lightgray;
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0;
}

.com-parada-puntos{
    letter-spacing: 1rem;
    font-weight: bold;
}

.com-parada-ruta-label{
    font-weight: bold;
}
/******************************************END PARADAS**************************************/

.com-detalle-ruta{
    margin-right: 10px;
    margin-left: 10px;
    padding: 15px;
    background-color: #F3F5FB;
    max-width: 100%;
    font-size: 0.95em;
    flex-wrap: wrap;
}

.com-detalle-ruta > div{
    margin-bottom: 10px;
}

.com-detalle-ruta  .com-gasto-titulo{
    overflow-x: hidden;
    overflow-y: hidden;
    min-width: 80px;
    max-width: 110px;
    word-break: break-word;
    height: 20px;

}

.com-detalle-ruta span{
    padding: 2px;
}
