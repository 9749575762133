.icon-tabs{
    display: flex;
    margin-top: 10px;
    width: 100%
}

.icon-tab{
    padding: 5px 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    border-radius: 4px 4px 0 0;
    border-bottom:1px solid #D9D9D9;
    cursor:pointer
}

.icon-tab-container{
    font-size: 0.8em;
}

.icon-tab:hover{
    background-color: #c4d8f1;
}