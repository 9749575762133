.seg-principal{
    display: flex;
    flex-grow: 1;
    position: relative;
}

.seg-seleccionar-tipo{
    position: fixed;
    left: 472px;
    top: 7px;
    z-index: 2;
    display: flex;
    gap:10px;
    align-items: center;
}

.seg-viajes{
    overflow-y: auto;
    max-height: calc(100vh - 160px);
}


.seg-viaje-tarjeta {
    display: flex;
    border: 1px solid rgba(164, 163, 170, 0.50);
    background: #FFF;
    align-items: center;
    gap: 10px;
    width: 390px;
}

.seg-filtro{
    display: flex;
    align-items: center;
    background: #FFF;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    gap: 5px;
    position: relative;
}

.seg-filtro-text{
    padding-left: 5px;
    flex-grow: 1;
}

.seg-seleccionar{
    padding-left: 8px;
}

.seg-unidad {
    display: flex;
    gap: 5px;
    color: #0081FF;
    font-weight: bold;
    align-items: center;
    position: relative;
}


.seg-cliente {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.seg-viaje {
    display: flex;
    flex-direction: column;
    line-height: 1.3;
    flex-grow: 1;
    padding: 3px 0;
    font-size: 0.9em;
    cursor: pointer;
}

.seg-ruta {
    color: rgba(111, 111, 111, 0.8);
}

.seg-indicadores {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    width: 320px;
}

.seg-alerta {

    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 10px;
    align-items: center;
    justify-content: center;
    height: 87px;
}

.estatus-alerta {
    display: flex;
    width: 100%;
    justify-content: center;
}

.small .p-inputswitch .small .p-inputswitch-slider:before {
    width: 1rem;
    height: 1rem;
}

.chip-viaje{
    display: grid;
    grid-template-columns: 1fr 5px 1fr 15px;
    padding: 4px 10px;
    align-items: center;
    gap: 13px;
    border-radius: 7px;
    border: 1px solid #A4A3AA;
    justify-content: space-between;
    margin-top: 5px;
}
.chip-viaje span{
    text-align: center;
}

.chip-viajes{
    width: 250px;
}

.seg-info-viaje{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 340px);
    margin-top: 10px;
}

.seg-info-viaje .estatus-viaje{
    font-weight: bold;
    color: #2196f3;
}

.seg-info-viaje .viaje{
    display: grid;
    grid-template-columns: 110px 1fr;
}

.seg-info-viaje .cliente{
    display: grid;
    grid-template-columns: 1fr 1fr;
}


.seg-viaje-ruta{
    display: grid;
    grid-template-columns: 20px 1fr 115px;
    font-size: 0.9em;
    column-gap: 5px;
    flex-grow: 1;
    overflow-y: scroll;
    align-content:start;
}


.seg-icon-ruta-container{
    position: relative;
    grid-row: span 2;
    padding-bottom: 10px;
}

.seg-direccion-completa{
    padding-bottom: 10px;
    font-size: 9px;
    color: #A4A3AA;
}

.seg-icon-ruta{
}

.seg-ruta-linea{
    border-left: 1px solid #0081FF;
    width: 1px;
    height: calc(100% - 15px);
    position: absolute;
    left: 7px;
    top: 18px;
}

.seg-ruta-linea.por-finalizar{
    border-left: 1px dashed #0081FF;
}

.seg-ruta-linea.last{
    display: none;
}


.seg-viaje-ruta .fecha{
}

.seg-info-viaje .gray-label{
    margin-top: 8px;
}

.seg-notas-operador{
    flex-grow: 1;
    overflow-y: auto;
    border: 1px solid #dedddd;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    padding: 7px;
    align-content: start;
}

.seg-header{
    text-align: center;
}

.seg-notas{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dedddd;
    padding: 5px;

}

.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

.seg-notificaciones{
    display: grid;
    grid-template-columns: 40px 150px auto;
    margin-top: 10px;
    font-size: 1.2em;
}

.seg-notificaciones > div{
    padding: 5px;
    display: flex;
    align-items: center;
}

.seg-operador{
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}


.seg-indicador-tootip{
    background-color: black;
    color: white;
}
.seg-indicador-tootip.p-overlaypanel-flipped::before {
    border-top-color: black;
}
.seg-indicador-tootip.p-overlaypanel-flipped::after {
    border-top-color: black;
}

.seg-indicador-tootip.p-overlaypanel::before {
    border-bottom-color: black;
}
.seg-indicador-tootip.p-overlaypanel::after {
    border-bottom-color: black;
}

.seg-indicador-tootip .p-overlaypanel-content{
    padding: 8px;
}

.seg-indicador-tootip{
    font-size: 0.9em;
}

.seg-indicador-tootip .t-value{
    font-weight: bold;
    margin-left: 5px;
}

.seg-indicador-tootip .t-label{
    font-size: 0.9em;
}

.seg-tooltip-signal {
    display: grid;
    grid-template-columns: 80px 1fr;
}

.seg-localizar-mapa{
    display: flex;
    flex-direction: column;
}

.seg-link{
    cursor: pointer;
}

.seg-link:hover {
    text-decoration: underline;
}

.seg-map-modal .p-dialog-header{
    padding: 2px 10px;
    background-color: #2E66FF;
    color: white;
}

.seg-map-modal .p-dialog-content{
    padding: 5px;
}

.seg-map-modal .p-dialog-header-icon.p-dialog-header-close{
    color: white;
}

.seg-icon:hover{
    cursor: pointer;
}

.seg-icon:hover .fill{
    fill: #d3d4e8;
}

.gris .seg-icon .fill, .seg-filtro.gris{
    fill: #E3E9ED;
    border-color: #E3E9ED;
}
.seg-alerta.gris{
    background-color: #E3E9ED;
}

.verde .seg-icon .fill, .seg-filtro.verde, .seg-alerta.verde{
    fill: green;
    border-color: green;
}

.seg-alerta.verde{
    background-color: green;
}

.amarillo .seg-icon .fill, .seg-filtro.amarillo{
    fill: #F9DA3A;
    border-color: #F9DA3A;
}

.seg-alerta.amarillo{
    background-color: #F9DA3A;
}

.rojo .seg-icon .fill, .seg-filtro.rojo{
    fill: #FF0000;
    border-color: #FF0000;
}

.seg-alerta.rojo{
    background-color: #FF0000;
}

.azul .seg-icon .fill, .seg-filtro.azul{
    fill: #2196F3;
    border-color: #2196F3;
}

.rosa .seg-icon .fill, .seg-filtro.rosa{
    fill: #f66abe;
    border-color: #f66abe;
}

.seg-mostrando-ruta .seg-icon .fill{
    fill: #bcbcbc;
}

.seg-expeditado{
    color: #fd3535;
    font-weight: bold;
    font-size: 1.1em;
    position: absolute;
    top: 2px;
    right: 6px;
}

.seg-carta-porte{
    color: rgba(111, 111, 111, 0.8);
    font-size: 0.8em;
}

.mapa-horas-slider{
    position: absolute;
    top:80px;
    left:10px;
    width: 80px;
    padding-top: 2px;
    padding-left: 5px;
    padding-bottom: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
}

.mapa-horas-slider .horas{
    margin-bottom: 20px;
    font-size: 0.9em;
    color: #979797;
    position: relative;
}

.mapa-horas-slider .export{
    position: absolute;
    scale: 0.6;
    top: 0;
    right: 0;
}

.mapa-horas-slider .rc-slider-mark-text{
    font-size: .9em;
    text-align: left;
    width: 70px;
}

.mapa-horas-slider .rc-slider-vertical .rc-slider-mark{

}

.seg-contenedor-mapa{
    width: 100%;
    height: 100%;
    position: relative;
}

.mapa-horas-slider .custom-slider{
    height: 400px;
}

.header-slider{
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}

.seg-modal{
    position: absolute;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    z-index: 100;
    left: 5px;
    top: 0px;
}

.seg-dialog-header{
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 10px;
}

.seg-dialog-title{
    font-weight: bold;
    font-size: 1.25rem;
}

.seg-dialog-close{
    position: absolute;
    right: 5px;
}

.seg-actions{
    position: absolute;
    right: -10px;
    display: flex;
    gap: 2px;
}

.seg-actions span{
    padding: 2px;
    border-radius: 20%;
}

.seg-actions span:hover{
    background-color: #d3d4e8;
}

.seg-mostrar-filtros{
    padding: 2px;
    border-radius: 20%;
}

.seg-mostrar-filtros:hover{
    background-color: #d3d4e8;
    cursor: pointer;
}

.seg-filter-icon{
    background-color: #E3E9ED;
    border-radius: 8px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.seg-filter-icon:hover{
    background-color: #d3d4e8;
}

.seg-filter-icon.selected {
    background-color: #a8a7a7;
}

.seg-total{
    width: 28px;
    padding-left: 8px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.seg-total-icon-container{
    display: flex;
    gap: 10px;
}

.seg-total-icon{
    cursor: pointer;
    display: flex;
    position: relative;
}


.seg-total-indicadores{
    width: 100%;
    background-color: white;
    display: flex;
    padding-bottom: 5px;
    gap: 10px;
}

.seg-filtros-indicadores{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;
}

.seg-filtro-indicador{
    display: flex;
    font-weight: bold;
    font-size: 0.8em;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.seg-filtro-indicador > div{
    border-radius: 50%;
    width: 13px;
    height: 13px;
    border: 2px solid #0b7ad1;
}

.seg-overlay-indicador .p-overlaypanel-content{
    padding: 10px;
}

.seg-filtro.gris.seleccionado, .seg-total-icon.gris{
    background-color: #E3E9ED;
}

.seg-filtro.verde.seleccionado, .seg-total-icon.verde{
    background-color: green;
}

.seg-filtro.amarillo.seleccionado, .seg-total-icon.amarillo{
    background-color: #F9DA3A;
}

.seg-filtro.rojo.seleccionado, .seg-total-icon.rojo{
    background-color: #FF0000;
}

.seg-filtro.azul.seleccionado, .seg-total-icon.azul{
    background-color: #2196F3;
}

.seg-filtro.rosa.seleccionado, .seg-total-icon.rosa{
    background-color: #f66abe;
}


.seg-total-cantidad{
    position: absolute;
    top: 4px;
    left: 30px;
    font-weight: bold;
    font-size: 1.1em;
}

.mapa-menu{
    position: absolute;
    z-index: 1;
    background-color: white;
    top:10px;
    left:210px;
    height: 40px;
    display: flex;
}

.mapa-menu-boton {
    background: none padding-box rgb(255, 255, 255);
    display: table-cell;
    border: 0;
    margin: 0;
    padding: 0 23px;
    text-transform: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    text-align: center;
    height: 40px;
    vertical-align: middle;
    color: rgb(0, 0, 0);
    font-family: Roboto, Arial, sans-serif;
    font-size: 18px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    min-width: 36px;
}

.mapa-menu-boton.checkbox{
    display: flex;
    align-items: center;
}

.mapa-menu-boton.checkbox .checkbox-label{
    margin-bottom: 0;
    margin-left:5px
}

.seg-seleccion-geocercas{
    max-height: calc(100vh - 270px);
    overflow-y: auto;
}

.seg-actions .seleccionado{
    background-color: #d3d4e8;
}

.routing-puntos-container{
    display: flex;
    align-items: center;
    gap:5px;
    padding-left: 5px;
    width: 100%;
}

.routing-sort{
    display: flex;
    padding: 5px;
}


.routing-text{
    flex-grow: 1;
    position: relative;
}

.routing-delete svg{
    font-size: smaller;
}

.routing-left-icon{
    width: 30px;
    display: flex;
    justify-content: center;
}

.routing-resultado{
    display: grid;
    grid-template-columns: 20px 1fr 30px;
    gap:8px
}

.routing-resultado .punto{
    width: 20px;
    height: 20px;
    padding: 3px;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
}

.seg-stop .title{
    font-size: 1.1em;
}

.seg-stop{
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.seg-stop .label{
    margin-right: 5px;
}

.seg-info-viaje .info-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.seg-viajes-cuenta-espejo{
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 3fr;
    max-height: 500px;
    overflow: auto;
}

.seg-viajes-cuenta-espejo > span{
    display: flex;
    align-items: center;
}

.portiempo{
    margin-top: 15px;
}

.con-unidades{
    font-weight: bold;
}

.unidades-cajas{
    font-size: 0.9em;
    color: gray;
}

.seg-estatus-seleccionado{
    font-weight: bold;
    font-size: 1.1em;
}

.seg-seguimiento-boton{
    cursor: pointer;
    background-color: #2196F3;
    color: white;
    padding: 2px 5px;
    border-radius: 4px;
}

.seg-indicador-atendido{
    display: flex;
    gap: 5px;
}

