
.prefacturacion-first {
    display: grid;
    grid: auto-flow / 2fr  2fr;
    grid-gap: 0.8rem;
}

.prefacturacion-header {
    display: grid;
    grid: auto-flow / 2fr  2fr;
    grid-gap: 0.8rem;
    margin-top: 1.5rem;
}

.prefacturacion-header-second {
    display: grid;
    grid: auto-flow / 1.5fr 3fr 1fr 2fr;
    grid-gap: 0.8rem;
}


.prefacturacion-header-seven {
    display: grid;
    grid: auto-flow / 95px 90px 90px;
    grid-gap: 0.8rem;
}


.prefacturacion-header-third {
    display: grid;
    grid: auto-flow / 1.5fr 3fr 1fr 2fr;
    grid-gap: 0.8rem;
    margin-top: 3.5rem;

}

.prefacturacion-four {
    display: grid;
    grid: auto-flow / 2fr  2fr;
    grid-gap: 0.8rem;
    margin-top: 1.5rem;
}



.prefacturacion-resumen {
    display: grid;
    grid: auto-flow / 1.5fr  1.5fr  1.5fr  1.5fr;
    grid-gap: 0.8rem;
    margin-top: 1.5rem;
}



.prefacturacion-five {
    display: grid;
    grid: auto-flow / 90px 90px;
    grid-gap: 0.8rem;
}

