.ex-grid {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    display: grid;
    border: 1px solid #dee2e6;
}

.grid-header{
    position: sticky;
    top: 0;
    z-index: 1;
}

.grid-header{
    padding: 5px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 0.875rem;
    background-color: #e9ecef;
}

.ex-grid .grid-cell{
    padding: 5px;
    display: flex;
    align-items: center;
    background-color: white;
    min-width: 0;
}

.center{
    justify-content: center;
}

.right{
    justify-content: right;
}
/*.ex-grid .command-button{*/
/*    padding: 5px;*/
/*}*/
