
.margintop{
    margin-top: auto;
}



.tablemargin{
    margin-bottom: 0 !important;
}



.table.tablemargin td, .table th {
     padding: .25rem; 
    vertical-align: top;
}


.minheight {
    min-height: 24em;

}

.maxheight {
    max-height: 5em;
}


.sticky-column {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0
}


.p-accordion-header-text {
    line-height: 1;
    width: 100%;
}


  .header-fixed {
    width: 100% 
}

 body{
    width:100%;
    overflow-x:hidden;
    overflow-y:hidden;
} 


.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 2px;
}


.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    height: 2px;

}


.p-accordion .p-accordion-tab .p-accordion-content {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
}


  #radioButtonOk .p-radiobutton .red .p-radiobutton-box.p-highlight {
    border-color: #dc3545;
    background: #dc3545;
}



button.secondary-icon.button-icon {
    border: none;
    background-color: transparent;
    border-radius: 0;
}


.section-container.danger > div.danger  {
    border-style: solid;
    border-color: red;
    border-width: 1px;
}


 #check > .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link{
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    height: 2px;
    border-color: red;
}


.error:not(.prime-form-control)
{   
    border-style: solid;
    border-color: red;
    border-width: 1px;

}



.error > .p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    height: 2px;
}


.taller-detalle-solicitud.table thead th{
    vertical-align: inherit;
}




.servicios-unidad{
    font-size: 1.05em;
    width: 70vw;
}

@media screen and (max-width: 1100px) {
    .servicios-unidad {
        width: 95vw;
    }
}