.login{
    display: flex;
    flex-direction: row;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    opacity: 0.9;
}

.login .sidebar {
    width: 450px;
    background-color: #0042E5;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.login .sidebar .iniciar-sesion{
    font-size: 2em;
    margin-bottom: 1em;
    color: white;
    font-weight: lighter;
}

.login .sidebar .form-login{
    width: 65%;
}

.login .sidebar .microsoft-login{
    margin-top: 25px;
    width: 65%;
}

.login .sidebar .form-login label{
    color: white;
    font-weight: lighter;
}

/*#4893ff #0042e5*/

.login > *{
    z-index: 1;
}

.logo{
    margin-top: 8%;
}

.login > h2{
    margin-top: 5%;
    color: #436AD9;
}

.login > form{
    margin-top: 3.5%;
    color: white;
}

.login .titulo-login{
    margin-bottom: 20px;
}

.btn-login{
    background-color: #d7d7d7;
    color: black;
}

.btn-login:hover{
    background-color: #ececec;
}
