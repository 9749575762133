.ope-inspeccion {
    position: relative;
}

.ope-inspeccion-imagenes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 35px;
}

.ope-inspeccion-imagenes > h6 {
    width: 100%;
}

.ope-inspeccion-imagenes > div {
    text-align: center;
    height: 100%;
}

.ope-daños {
    position: relative;
}

.ope-daños-expandir {
    right: 10px;
    top: 10px;
    position: absolute;
    background-color: #e9ecef;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.section-container .ope-daños-contraer {
    right: 0;
    top: 0;
    position: absolute;
    background-color: black;
    height: 55px;
    width: 50px;
    text-align: center;
    color: white;
    font-size: 0.8em;
    padding: 4px;
    cursor: pointer;
}

.section-container .ope-daños-contraer > label {
    width: 100%;
    margin: 0;
}

/************************PATIOS LLANTAS**************************************/

.ope-contenedor-llanta {
    display: grid;
    grid-template-columns: 20px 1.4fr 200px 20px 1.4fr;
    row-gap: 5px;
    height: 500px;
}

.remolque.ope-contenedor-llanta {
    align-content: end;
}

.unidad.ope-contenedor-llanta {
    align-content: start;
}

.ope-contenedor-llanta > .ope-contenedor-imagen {
    position: relative;
    grid-column: 3;
    grid-row: 1 / span 10;
    margin-top: -10px;
}

.ope-imagen-llantas {
    position: absolute;
    left: 15px;
    max-width: 175px;
    max-height: 500px;
}

.ope-llanta-ok {
    border: 1px solid green;
}

.ope-llanta-error {
    border: 1px solid darkred;
}

.revision-llanta-warning {
    background-color: #f7dc6f;
}

.revision-llanta-error {
    background-color: #f1948a;
}

.revision-llanta-ok {
    background-color: #7dcea0;
}

.dot {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    display: inline-block;
    margin: 1px;
}

.dot2 {
    width: 1.0rem;
    height: 1.0rem;
    border-radius: 50%;
    display: inline-block;
    margin: 1px;
}

.unidad .ope-imagen-llantas {
    top: 0;
}

.remolque .ope-imagen-llantas {
    bottom: 10px;
}

/***************************PATIOS******************************/

.ope-menu-patios {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media screen and (max-width: 1200px) {
    .ope-menu-patios {
        flex-direction: column;
    }
}

.is-tablet .ope-menu-patios {
    height: 85vh;
}

.ope-item-menu-patios {
    height: 380px;
    width: 380px;
    margin: 10px 40px;
    cursor: pointer;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 250px;
}

.ope-item-menu-patios.accesos {
    background-color: #436ad9;
}

.ope-item-menu-patios.salidas {
    background: #183d8e;
}

.align-Buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ope-contenedor-opcionesllanta {
    justify-content: center;
    align-items: flex-start;
    flex-direction: row-reverse;
    width: 100%;
}

/***************************SEGUIMIENTO******************************/
.ope-button-edi {
    width: 100%;
    height: 30px;
}

/***************************VIAJES******************************/
tr.viaje-segmento .custom-panel.empty {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2rem #a6d5fa;
    box-shadow: 0 0 0 0.1rem #a6d5fa;
    border-color: #2196f3;
    border-radius: 0.25rem;
}

.viaje-en-calendario {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    padding-left: 0.5rem;
}

.viaje-en-calendario > div {
    white-space: nowrap;
    overflow: hidden;
}

.viaje-en-calendario .viaje-tooltip {
    top: 0;
    background-color: white;
    padding: 0.5rem;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.status-terminado {
    border: 2px solid #3bc379;
    background-color: rgb(166, 227, 194, 0.8);
}

.status-ruta {
    border: 2px solid #5e7fde;
    background-color: rgb(189, 203, 242, 0.8);
}

.status-programado {
    border: 2px solid #dfdfdf;
    background-color: rgb(255, 255, 255, 0.8);
}

.status-por-programar {
    border: 2px solid #dbdbdb;
    background-color: rgb(247, 246, 246, 0.8);
}

.status-terminado.vacio {
    background-image: linear-gradient(
        125deg,
        #93cc85 6.52%,
        rgb(166, 227, 194, 0.8) 6.52%,
        rgb(166, 227, 194, 0.8) 50%,
        #93cc85 50%,
        #93cc85 56.52%,
        rgb(166, 227, 194, 0.8) 56.52%,
        rgb(166, 227, 194, 0.8) 100%
    );
}

.status-ruta.vacio {
    background-image: linear-gradient(
        125deg,
        #acb8e6 6.52%,
        rgb(189, 203, 242, 0.8) 6.52%,
        rgb(189, 203, 242, 0.8) 50%,
        #acb8e6 50%,
        #acb8e6 56.52%,
        rgb(189, 203, 242, 0.8) 56.52%,
        rgb(189, 203, 242, 0.8) 100%
    );
}

.status-programado.vacio {
    background-image: linear-gradient(
        125deg,
        #dbdbdb 6.52%,
        rgb(255, 255, 255, 0.8) 6.52%,
        rgb(255, 255, 255, 0.8) 50%,
        #dbdbdb 50%,
        #dbdbdb 56.52%,
        rgb(255, 255, 255, 0.8) 56.52%,
        rgb(255, 255, 255, 0.8) 100%
    );
}

.status-por-programar.vacio {
    background-image: linear-gradient(
        125deg,
        #dbdbdb 6.52%,
        rgb(247, 246, 246, 0.8) 6.52%,
        rgb(247, 246, 246, 0.8) 50%,
        #dbdbdb 50%,
        #dbdbdb 56.52%,
        rgb(247, 246, 246, 0.8) 56.52%,
        rgb(247, 246, 246, 0.8) 100%
    );
}

.status-terminado.vacio,
.status-ruta.vacio,
.status-programado.vacio,
.status-por-programar.vacio {
    background-size: 14.65px 20.92px;
}

.viaje-a-tiempo .p-progressbar .p-progressbar-value {
    background-image: linear-gradient(
        125deg,
        #00b050 15%,
        #00d661 15%,
        #00d661 50%,
        #00b050 50%,
        #00b050 65%,
        #00d661 65%,
        #00d661 100%
    );
}

.viaje-retrasado .p-progressbar .p-progressbar-value {
    background-image: linear-gradient(
        125deg,
        #d50505 15%,
        #ff4b4b 15%,
        #ff4b4b 50%,
        #d50505 50%,
        #d50505 65%,
        #ff4b4b 65%
    );
}

.viaje-a-tiempo .p-progressbar .p-progressbar-value,
.viaje-retrasado .p-progressbar .p-progressbar-value {
    background-size: 12.21px 17.43px;
}
/***************************FIN VIAJES******************************/

.ope-llantas-center {
    margin: auto;
    width: 90%;
    margin-bottom: 5px;
}

.ope-alineacion-checklist {
    display: flex;
    margin-top: 18%;
}

.ope-alineacion-puntoInspeccion {
    margin-top: 16%;
}

.ope-alineacion-tarifa {
    max-width: 140px;
}


