.col-auto {
    display: flex;
}

.form-row .col-auto {
    padding-left: 0;
    padding-right: 0;
}

.prime-form-control {
    width: 100%;
}

.full-width-control,
.full-width-control .p-autocomplete-input {
    width: 100%;
}

.prime-form-control.p-inputwrapper.p-autocomplete {
    display: block;
}

.col-auto .prime-form-control {
    margin-right: 5px;
    padding-left: 0;
}

.col-auto .prime-form-control:nth-last-child(1) {
    margin-right: 0;
}

/**********************DATEPICKER**************************************/

.prime-form-control .p-inputtext,
.prime-form-control .p-datepicker-trigger.p-button.p-button-icon-only,
.prime-form-control.p-multiselect .p-multiselect-label {
    padding-top: 6px;
    padding-bottom: 6px;
}

.small.p-datepicker.p-component {
}

.small .p-datepicker table,
.xs .p-datepicker table,
body > .p-datepicker table {
    font-size: 0.8rem;
}

.p-datepicker table th,
.p-datepicker table td {
    border-top: none;
}

.small .p-datepicker table td {
    padding: 0.2rem;
}

.small .p-datepicker table td > span {
    width: 2rem;
    height: 2rem;
}

.small .p-datepicker table td > span,
body > .p-datepicker table td > span {
    width: 2rem;
    height: 2rem;
}

.p-calendar.large-padding-bottom .p-datepicker {
    padding-bottom: 30px;
}

.xs .p-datepicker table td > span {
    width: 1rem;
    height: 1rem;
}

/**********************END DATEPICKER**************************************/

.prime-form-control .p-autocomplete-input {
    width: 100%;
}

.prime-form-control .p-autocomplete-input.p-disabled {
    background-color: #e9ecef;
}

.autocomplete-not-found-item {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    color: white;
    padding: 2px 10px 2px 10px;
    box-shadow: 2px 2px 2px #888888;
}

.p-inputnumber.p-component.form-control {
    border: 0;
    padding: 0;
}

.p-component.p-inputnumber-input.form-control {
    width: 100%;
}

.form-filtro {
    position: relative;
}

/**************************TAB VIEW*******************************/

.p-tabview.p-component {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.p-tabview .p-tabview-panels {
    flex-grow: 1;
}

.p-tabview .p-tabview-panels > div {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.p-tabview .pi-exclamation-circle {
    color: red;
}

.main-tab-view {
    background-color: #f3f5fb;
}

.main-tab-view.p-tabview .p-tabview-nav {
    background-color: #f3f5fb;
    border: none;
}

.main-tab-view.p-tabview .p-unselectable-text.p-tabview-selected > a.p-tabview-nav-link,
.main-tab-view.p-tabview .p-unselectable-text > a.p-tabview-nav-link,
.main-tab-view.p-tabview .p-tabview-panels {
    background-color: #f3f5fb;
}

.main-tab-view.p-tabview .p-sidebar .p-tabview-panels {
    background-color: white;
}

.main-tab-view.p-tabview .p-sidebar .p-tabview-nav {
    background-color: white;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;
}

.main-tab-view.p-tabview .p-sidebar .p-tabview .p-tabview-nav li.p-highlight a.p-tabview-nav-link {
    background: #ffffff;
    border-color: #2196f3;
}

.main-tab-view.p-tabview .p-sidebar .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: white;
}

.main-tab-view.p-tabview .p-sidebar .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: white;
}

.main-tab-view.p-tabview .p-unselectable-text.p-tabview-selected > a.p-tabview-nav-link {
    border-color: black;
}

.p-tabview .p-tabview-nav li.p-highlight a.p-tabview-nav-link {
    color: black;
}

.p-tabview .p-tabview-nav li a.p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.tab-secondary.p-tabview .p-tabview-panels {
    padding: 1rem 0 1rem;
}

/**************************TAB VIEW*******************************/

.grid-principal {
    flex-grow: 1;
}

.is-tablet .grid-principal {
    height: 70vh;
    width: 90vw;
}

@media screen and (max-height: 700px) {
    .is-tablet .grid-principal {
        height: 60vh;
        width: 90vw;
    }
}

.combustion .ag-theme-balham {
    font-size: 0.9em;
}

.p-inputtext.p-component.right {
    text-align: right;
}

.table td.input {
    padding: 0.2rem 0.1rem 0 0.1rem;
}

.table td.input .image-upload > label{
    margin: 0;
}

/*.input .RiDeleteBinLine{*/
/*    font-size: 1.8em;*/
/*}*/

.table th {
    padding: 0.5rem;
}

.table th.input {
    padding: 0.2rem;
}

.table.with-thumbnail > tbody > tr > td:not(.thumbnail) {
    padding-top: 18px;
    padding-bottom: 0;
}

.table.with-thumbnail > tbody > tr > td.input {
    padding-top: 10px;
}

.table.with-thumbnail > tbody > tr > td.thumbnail {
    padding-top: 4px;
    padding-bottom: 4px;
}

.p-inputgroup > .p-inputgroup-addon {
    padding: 0.4rem 0.4rem;
}

.ContainerForm {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
}

div .ag-theme-balham .ag-header {
    background: rgb(243, 245, 251);
    border-color: transparent;
}

div .ag-theme-balham .ag-root-wrapper {
    border: none;
}

.ag-row.amarillo .ag-cell {
    color: #e1bb53;
}

.ag-row.rojo .ag-cell {
    color: #FF0000;
}

.ag-row.azul .ag-cell {
    color: #3788d8;
}

.ag-row.cafe .ag-cell {
    color: #a18262;    
}

.ag-row.naranja .ag-cell {
    color: #FF9900;
}
.ag-row.verde .ag-cell {
    color: #1ba024;
}
.ag-row.blanco .ag-cell {
    background-color: #ffffff;
}
.ag-row .ag-cell.imagen {
    padding-left: 5px;
}
.sick-days-breach {
    background-color: lightcoral !important;
}
/*  ************************RADIO BUTTONLIST*************************/

.field-radiobutton-list {
    display: flex;
    width: 100%;
    padding: 0.4rem;
}

.field-radiobutton-list > .p-field-radiobutton {
    margin: 0;
}

.field-radiobutton-list > .p-field-radiobutton:not(:first-child) {
    margin-left: 20px;
}

.field-radiobutton-list.column{
    margin-left: 0;
}

.field-radiobutton-list label {
    margin-left: 0.3rem;
    margin-bottom: 0.05rem;
    cursor: pointer;
}

.form-radiobutton-list > .p-field-radiobutton {
    margin-bottom: 0;
    padding: 0.5rem 0.5rem 0.5rem 0;
}

/*  ************************FIN RADIO BUTTONLIST*************************/

/*  ************************CHECK BOX*************************/

.field-checkbox {
    display: flex;
    width: 100%;
    margin: 0.4rem;
}

.form-group .field-checkbox {
    padding-top: 8px;
}

.field-checkbox label {
    margin-left: 0.3rem;
    margin-bottom: 0.05rem;
    cursor: pointer;
}

.table-sm .field-checkbox {
    margin: 2px 0 0 0;
}

.p-checkbox.p-component.disabled > .p-checkbox-box {
    cursor: default;
}
.p-checkbox.p-component.disabled > .p-checkbox-box:hover {
    border-color: #ced4da;
}
.p-checkbox.p-component.disabled > .p-checkbox-box.p-highlight:hover {
    border-color: #2196f3;
    background-color: #2196f3;
}

.form-checkbox-list .p-field-checkbox > label{
    margin-bottom: 0;
}

.table.table-sm td.input {
    padding: 0;
}

.table-borderless th,
.table-borderless td {
    border-top: 0 !important;
}
/*  ************************FIN RADIO BUTTONLIST*************************/

/*  ************************MEDIAS*************************/
@media screen and (max-width: 64em) {
    .p-sidebar-left .p-sidebar-lg,
    .p-sidebar-left .p-sidebar-md,
    .p-sidebar-right .p-sidebar-lg,
    .p-sidebar-right .p-component.p-sidebar-md {
        width: 35rem;
    }
}

/*  ************************END MEDIAS*************************/

/*  ************************SIDEBAR*************************/
.p-component {
}

.p-sidebar.p-component .p-sidebar-header {
    padding: 0.5rem 0.5rem 0 1rem;
}

.p-sidebar-left.p-sidebar-bg,
.p-sidebar-right .p-sidebar.p-sidebar-bg {
    width: 50rem;
}

.p-sidebar-left.p-sidebar-xl,
.p-sidebar-right .p-sidebar.p-sidebar-xl {
    width: 80rem;
}

/*  ************************END SIDEBAR*************************/

/*  ************************ERRORES VALIDACIÓN*************************/
.prime-form-control.error > input {
    width: 100%;
    border-color: red;
}

.p-dropdown.p-component.prime-form-control.error {
    border-color: red;
}

.p-multiselect.p-component.p-inputwrapper.p-multiselect-clearable.prime-form-control.error {
    border-color: red;
}

.p-inputtext.p-component.form-control.error {
    border-color: red;
}

.field-radiobutton-list.error,
.submitted .clipboard-image.validation-error {
    border: 1px solid red;
    border-radius: 5px;
}

.submitted .validation-error,
.submitted .validation-error > .p-inputtext {
    border-color: red;
}

.submitted .form-radiobutton-list.validation-error,
.submitted .image-upload.validation-error {
    border: 1px solid red;
}

/*  ************************END ERRORES VALIDACIÓN*************************/

/**************************AG GRID ********************************/
.ag-header-cell-label {
    justify-content: center;
    font-weight: bold;
    color: black;
}

.ag-cell {
    text-align: center;
}

.ag-cell.left {
    text-align: left;
}

.ag-cell.right {
    text-align: right;
}

.cell-flex .ag-cell {
    display: flex;
    align-items: center;
}

/*  ************************END AG GRID*************************/

/*  ************************DROP DOWN*************************/
.dropdown-bottom .p-dropdown-panel {
    min-height: 200px;
}

.dropdown-custom {
    width: 100%;
    height: 31px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.panel-sm > .dropdown-custom {
    height: 25px;
}

.dropdown-custom-option {
    padding: 0.25rem 0.5rem;
}

.dropdown-custom-option:hover {
    background-color: #e9ecef;
    cursor: pointer;
}

.dropdown-custom-option.highlight,
tr.highlight > td {
    background: #e3f2fd;
}

.filter-panel > div,
.dropdown-custom-option > div {
    padding: 0 0.25rem;
}

select.form-control-sm {
    font-size: 0.8rem;
}

.custom-panel.disabled {
    background-color: #f2f4f5;
    opacity: 0.5 !important;
}

.custom-panel:not(.disabled) .dropdown-custom {
    cursor: pointer;
}
.p-multiselect-filter-container {
    min-width: 70px;
}
/*  ************************END DROP DOWN*************************/

/*  ************************FILE UPLOAD*************************/
.image-upload {
    display: flex;
}

.image-upload.error {
    color: red;
}

.image-upload > input {
    display: none;
}

.image-upload {
    font-size: 1.3rem;
    width: 100px;
}

.image-upload label {
    cursor: pointer;
}

.image-upload .image-container {
    position: relative;
    width: 100px;
}

.image-upload .download {
    margin-right: 15px;
}

.image-upload .clear {
    position: absolute;
    top: 0;
    right: 0;
    color: black;
    font-size: 1.2rem;
}

.image-upload .download-image {
    position: absolute;
    top: 0;
    color: black;
    font-size: 1.2rem;
}

/*  ************************END FILE UPLOAD*************************/

/*  ************************SELECT BUTTON *************************/
.col-auto .p-selectbutton.p-buttonset {
    display: flex;
}

.col-auto .p-selectbutton.p-buttonset .p-button {
    padding-top: 6px;
    padding-bottom: 6px;
}

/*  ************************END SELECT BUTTON *************************/

/*  ************************FULL CALENDAR*************************/
.fc-toolbar-chunk {
    display: flex;
}

.fc-timegrid-event.fc-v-event {
    border: none;
    background-color: transparent;
}

.fc-v-event .event {
    font-size: 0.75rem;
    padding: 2px;
    overflow: hidden;
    height: 100%;
}

.fc-v-event .event.tipo-0 {
    background-color: #3788d8;
}

.fc-v-event .event.tipo-1 {
    background-color: #97ecc0;
    color: #188363;
    font-weight: bold;
}

.fc-v-event .event.tipo-2 {
    background-color: #faacae;
    color: #f23539;
    font-weight: bold;
}

.fc-v-event .event.tipo-3 {
    background-color: gray;
}

.medida.alto td {
    border-top: none;
}

/*  ************************END FULL CALENDAR*************************/

.tr-expand-collapse td {
    border-bottom: none;
}

.tr-expand-collapse i {
    cursor: pointer;
}

.tr-expand-collapse label {
    cursor: pointer;
    font-weight: bold;
}

tr.medida td:first-child {
    padding-left: 30px;
}

/*  ************************CAMERA*************************/

.react-html5-camera-photo {
    width: 100%;
}

.camara .react-html5-camera-photo > video {
    width: 100%;
}

.react-html5-camera-photo .container-circles {
    top: 30px;
}

.camara {
    width: 400px;
}

.camara .react-html5-camera-photo > #container-circles {
    top: 5px;
    width: 80px;
    height: 80px;
}

.camara .react-html5-camera-photo .outer-circle {
    height: 50px;
    width: 50px;
}

.camara .react-html5-camera-photo .outer-circle > .inner-circle {
    height: 30px;
    width: 30px;
    left: 64%;
    top: 64%;
}

.camara.error {
    border: 3px solid red;
}

.camara .image-container {
    position: relative;
    width: 100%;
}

.camara .download {
    margin-right: 15px;
}

.camara .clear {
    position: absolute;
    top: 0;
    right: 0;
    color: black;
    font-size: 1.2rem;
}

.camara .download-image {
    position: absolute;
    top: 0;
    color: black;
    font-size: 1.2rem;
}

.thumbnail-modal {
    width: 100%;
    display: flex;
    justify-items: flex-end;
}

.thumbnail-modal .margenes {
    flex-grow: 1;
}

/*  ************************END CAMERA*************************/
.image-marker > .image-marker__marker {
    width: 20px;
    height: 20px;
    font-size: 0.8em;
    padding-top: 1px;
    border: 1px solid white;
    box-shadow: 2px 2px lightgray;
    background-color: #f55d61;
    border-radius: 50%;
    color: white;
    text-align: center;
}

/*  ************************CHECK LIST*************************/
.checklist-error td.option {
    border-top: 1px solid red;
    border-bottom: 1px solid red;
}

.checklist-error td.option.ok {
    border-left: 1px solid red;
}

.checklist-error td.option.no-ok {
    border-right: 1px solid red;
}

/*  ************************END CHECK LIST*************************/

/************************CUSTOM TABLE******************************/
.table-custom {
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.table-custom .table {
    height: 98%;
    margin-bottom: 0;
}

.table-fixed tbody {
    display: block;
    overflow-x: hidden;
    height: 94%;
    overflow-y: scroll;
}

.table-fixed thead,
.table-fixed tbody tr,
.table-fixed tfoot {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table-fixed thead,
.table-fixed tfoot {
    width: calc(100% - 1.3em);
}

.table-fixed td,
.table-fixed th {
    word-wrap: break-word;
    height: 25px;
}

.table-custom .table thead tr,
.filter-panel tr th {
    background-color: #f3f5fb;
}

.table-custom .table thead th {
    border-top: none;
    border-bottom: none;
    text-align: center;
}

.table-custom .table thead th.right {
    text-align: right;
    padding-right: 10px;
}


.table-custom .table thead.filter-panel th {
    text-align: left;
}

.table-custom .table tbody tr:first-child td {
    border-top: none;
}

.table-custom .table-striped tbody tr:nth-of-type(even) {
    background-color: #fcfdfe;
}

.table-custom .table-striped tbody tr:nth-of-type(odd) {
    background-color: white;
}

.table-custom .table-hover tbody tr:nth-of-type(even):hover,
.table-custom .table-hover tbody tr:nth-of-type(odd):hover {
    background-color: #ecf0f1;
}

.table-custom .table th,
.table-custom .table td {
    vertical-align: middle;
}

.table-custom .table th,
.table-custom .table td,
.table-custom i.pi,
.table-custom button.btn {
    font-size: 0.9em;
}

.table-in-panel tbody {
    display: inline-block;
    max-height: 120px;
}

.table-in-panel tbody tr {
    cursor: pointer;
}

.table-custom .p-progressbar {
    height: 0.55rem;
}
/**********************END CUSTOM TABLE****************************/

/**********************CALENDARIO****************************/
.calendario {
    position: relative;
    overflow: auto;
}

.calendario-header,
.calendario-contenedor,
.calendario-eventos {
    display: grid;
    position: relative;
}

.calendario-botones {
    width: 100%;
    position: absolute;
}
.calendario-contenedor .hora.detalle {
    border-right: 1px solid #dee2e6;
    z-index: 1;
    background-color: white;
}

.calendario-contenedor .items {
    background-color: white;
    z-index: 1;
}

.calendario > .contenedor-items {
    position: absolute;
    top: 0;
    width: 100%;
}

.calendario > .contenedor-items .item {
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
}

.calendario > .contenedor-items .item > div {
    width: 100%;
    height: 90%;
    border-radius: 5px;
}

.calendario > .contenedor-items .item.no-border-left > div {
    border-left: none;
    border-radius: 0 5px 5px 0;
}

.calendario > .contenedor-items .item.no-border-right > div {
    border-right: none;
    border-radius: 5px 0 0 5px;
}
/**********************FIN CALENDARIO****************************/

.progressbar-sm .p-progressbar {
    height: 1rem;
}

.inputswitch-sm.p-inputswitch {
    height: 1rem !important;
    width: 2rem !important;
}

.inputswitch-sm.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    -webkit-transform: translateX(0.85rem) !important;
    transform: translateX(0.85rem) !important;
}

.inputswitch-sm.p-inputswitch .p-inputswitch-slider:before {
    width: 0.8rem !important;
    height: 0.8rem !important;
    left: 0.15rem !important;
    margin-top: -0.4rem !important;
}

.p-overlaypanel.overlaypanel-sm .p-overlaypanel-content {
    height: 250px;
    overflow: auto;
}

.arrow-right.p-overlaypanel:after,
.arrow-right.p-overlaypanel:before {
    right: 1.25rem;
    left: initial;
}

.swal2-container.swal2-center {
    z-index: 2147483647;
}

.columns.is-mobile > .buttons.column.is-3 {
    width: 40%;
}

.box.is-mobile {
    padding: 10px;
}

.notificacion{
    cursor: pointer;
}

.notificacion .p-badge{
    min-width: 1.2rem;
    height: 1.2rem;
    font-size: 0.65rem;
    line-height: 1.2rem;
}


.p-sidebar.notificaciones{
    position: relative;
}

.p-sidebar.notificaciones .p-sidebar-header{
    box-shadow: 0 0 1px;
    margin-bottom: 0.5rem;
}

.p-sidebar.notificaciones .p-sidebar-content{
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.p-sidebar.notificaciones .notificaciones-header{
    position:absolute;
    top:5px;
    font-size: 1.15rem;
    font-weight: 500;
}

.f-group{
    padding-left: 15px;
    padding-right: 15px;
}

.gray-label{
   color: #A4A3AA;
}

.form-value{
    font-weight: bold;
}

.center-buttons{
    display: flex;
    justify-content: center;
    gap: 10px
}

.p-dialog.custom-footer .p-dialog-content  {
    padding-bottom: 20px;
}

.p-dialog.small-header .p-dialog-header  {
    padding-bottom: 5px;
}


.dialog-footer{
    margin-top: 20px;
}

.routing-result{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.p-chips .p-inputtext.p-chips-multiple-container{
    padding: 0 5px;
    width: 100%;
}