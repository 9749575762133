
.estacion{
    display: flex;
    flex-direction: column;
}


.estacion-header{
    padding: 5px;
    border: 1px solid #a3aad2;
    cursor: default;
    margin-bottom: 3px;
    width: 200px;
}

.estacion-detalle{
    display: flex;
    overflow: auto;
    gap: 5px;
    flex-wrap: wrap;
}

.estacion-viaje{
    font-size: 0.7em;
    display: flex;
    gap: 6px;
    align-items: center;
    position: relative;
}

.estacion-viaje .unidad{
    font-weight: bold;
    font-size: 1.2em;
}

.estacion-viaje .cliente{
    width: 70px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.estacion-viaje .info{
    position: absolute;
    top: -3px;
    right: -3px;
    font-size: 10px;
}

.estacion-viaje .info > .p-button.p-button-icon-only.p-button-rounded{
    width: 17px;
    height: 17px;
}

.estacion-viaje .info > button > span{
    font-size: 12px
}


.estacion-info-viaje{
    display: grid;
    grid-template-columns: 100px 300px;
    font-size: 0.85em;
    gap: 5px;
}

.viaje-estaciones-header{
    margin-bottom: 5px;
    display: flex;
    gap: 5px
}