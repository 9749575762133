
.tanque-vista{
    width: 100%;
    position: relative;
    background-color: #dae3f3;
    height: 40px;
    border-radius:8px;
    box-shadow: 3px 5px 2px #dedede;
    margin-bottom: 30px;
    font-size: 0.85em;
}

.tanque-vista .ubicacion{
    position: absolute;
    z-index:3;
    color: black;
    font-weight: bold;
    font-size: 1em;
    top:3px;
    left:15px;
}

.tanques-container{
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-gap: 15px;
}

.tanque-vista .segmento{
    position: absolute;
    height: 40px;
    width: 1px;
    background-color: lightgrey;
    z-index: 2;
}

.tanque-vista .segmento.ultimo{
    background-color: transparent;
}

.tanque-vista .segmento .label {
    position: relative;
}

.tanque-vista .segmento .label > span {
    position: absolute;
    top: 45px;
    left: -30px;
    display: flex;
    white-space: nowrap;
}

.tanque-vista .advertencia{
    z-index: 2;
    position: absolute;
    height: 40px;
    width: 2px;
    background-color: red;
}

.tanque-vista .bandera{
    z-index: 2;
    position: absolute;
    height: 40px;
    width: 2px;
    background-color: yellow;
}

.tanque-vista .disponible {
    border-radius: 8px 0 0 8px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0d89ec;
}

.tanque-vista .disponible .label {
    position: relative;
}

.tanque-vista .disponible .label .texto {
    position: absolute;
    top: -30px;
    right: -28px;
    display: flex;
    font-weight: bold;
}

.tanque-vista .disponible .label .indicador{
    position: absolute;
    top: -10px;
    right: -6px;
    display: flex;
    font-weight: bold;
}

.rendimiento-diesel .ex-field-label > button{
    padding-top: 0;
    padding-bottom: 3px;
}
