.layout {
    box-sizing: border-box;
    padding: 0.2em;
    height: 100vh;
    display: flex;
}

.main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: #F3F5FB;
}

.main > article {
    flex-grow: 1;
    border-top: #E2E3DF 1px solid;
    padding: 0.6em;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.header {
    font-size: 0.9em;
    background-color: white;
    height: 50px;
    display: flex;
    align-items: center;
}

.aplicacion > h1 {
    margin: 2px;
    font-size: 1.7em;
    padding-left: 20px;
}

.aplicacion {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.header > * {
    margin-right: 10px;
}

.navigation-container {
    font-size: 1rem;
}

.navigation {
    background-color: #0042e5;
    /*background-image: url('../img/cintilloxmas.png');
    background-repeat: no-repeat;
    background-position-y: bottom;*/
    display: flex;
    flex-direction: column;
    border-radius: 0 10px 10px 0;
    width: 55px;
    transition: width 800ms;
    height: 100%;
}

.navigation.expandido {
    width: 250px;
}

.navigation > .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
}

.navigation > .menu {
    border-top: #2327BE 1px solid;
    flex-grow: 1;
    position: relative;
    font-size: 1.2em;
    color: white;
    display: flex;
    flex-direction: column;
}


.menu .cambiar {
    position: absolute;
    bottom: 5px;
    right: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.menu > .cambiar div {
    font-size: 1.5em;
}

.menu .nombre {
    margin-left: 10px;
}

.menu .menu-item {
    padding: 10px 5px 10px 15px;
    height: 60px;
    width: 60px;
    overflow: hidden;
    transition: width;
    transition-delay: 1s;
}

.menu .menu-item.expandido {
    width: 250px;
    transition-duration: 0s;
    transition-delay: 0s;
}

.menu .menu-item:hover {
    cursor: pointer;
    color: white;
}

.menu .menu-item.activo {
    background-color: white;
    border-radius: 20px 0 0 20px;
    cursor: pointer;
    color: blue;
}


.menu-item .nombre {
    cursor: pointer;
    visibility: hidden;
    transition: visibility 700ms;
}


.submenu-item {
    display: flex;
    margin: 20px 15px 30px 15px;
    font-size: 0.9em;
}

.submenu {
    position: absolute;
    background-color: white;
    width: 350px;
    right: -350px;
    top: 0;
    padding: 10px;
    color: blue;
    height: 100%;
    border-radius: 0 15px 15px 0;
    z-index: 10;
    box-shadow: 5px 5px 2px grey;
}



.logoLandingPage-padre {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /*background-color: white;*/
}

.logoLandingPage-hijo {
    height: 100%;
    width: 100%;
}


.check-list.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #e4eaf1;
}

.check-list.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #415264;
}


.check-list.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {

    border: solid red;
}


.check-list.p-inputswitch .p-inputswitch-slider:before {
    background: #ffffff;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 50%;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    border: solid green;

}

.check-list.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #c3d0da !important;
}

.p-unselectable-text.p-tabview-selected.p-highlight {
    width: auto !important;
}

.accordion-title {
    background-color: #FFF;
    border-color: #fff;
    color: #007bff;
}

/*Configuracion Impresion */

@media all {
    .page-break {
        display: none;
    }
}

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: none;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: none;
    }
}

@page {
    size: auto;
    margin: 20mm;
}

@media print {

    header,
    footer {
        display: none;
    }
}
