.caja {
    width:100%;
    //grid-template-columns:  repeat(5, 1fr) ;
}

.caja-tabs {
    display: flex;
    grid-gap: 5px;
    margin-bottom: 10px;
}

.caja-tabs .caja-tab{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #dedddd;
    padding: 5px 5px 5px 15px;
    font-size: 0.80em;
    color: #930101;
    grid-gap: 3px;
    min-width: 140px;
    border-radius: 5px;
    cursor: pointer;
}

.caja-tabs.departamento .caja-tab{
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.caja-tabs .caja-tab:hover{
    border: 1px solid #a3aad2;
}

.caja-tabs .caja-tab.activa{
    color: #4b6fdb;
    border: 1px solid #a3aad2;
    box-shadow:  2px 2px 2px #a8a8a8;
    cursor: default;
}

.caja-tab .monto{
    font-weight: bold;
    font-size: 1.1rem;
}


.caja .cell-caja{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    grid-gap: 2px;
    align-items: flex-start;
}

.caja .cell-caja > span{
    height: 15px;
    line-height:normal !important;
}

.caja .cell-caja > .tipo{
    text-transform: uppercase;
}

.caja .cell-caja > .metodo-pago{
    color: gray;
}

.caja .gastos {
    display:grid;
    grid-template-columns:4fr 2fr 100px 40px;
    width:100%;
    border: 1px solid #dfdfdf;
    border-bottom: none;
}

.caja .cell-gasto{
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #dfdfdf;
    align-items: center;
    display: flex;
}

.caja .cell-gasto .oculto{
    visibility: hidden;
}

.caja .cell-gasto .pi {
    margin-right: 15px;
}


.caja .cell-gasto .ex-grid {
    border:none;
}

.caja .cell-gasto.collapsed{
    display: none;
}

.caja .total-abonado{
    color: #2760D3;
    font-weight: bold;
}
