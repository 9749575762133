.table td,
.table th {
  padding: 0.25rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table.Atender td,
.table.Atender th {
  padding: 0.25rem;
  vertical-align: top;
  border-top: none;
  position: relative;
}

/* table.Atender tr td:nth-child(1) {
  border-left: 1px solid #ced4da;
} */
